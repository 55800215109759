module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Perfectly Penned Blog","short_name":"Perfectly Penned","start_url":"/","background_color":"#fff","theme_color":"#00a79c","display":"minimal-ui","icons":[{"src":"/icons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/icons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/icons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/static/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/static/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/static/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/static/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/static/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/static/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/static/icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/static/icons/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/static/icons/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"/static/icons/apple-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/static/icons/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"/static/icons/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/static/icons/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/static/icons/apple-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/static/icons/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/static/icons/apple-icon-180x180.png","sizes":"180x180","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDB97VZ","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:300,400","sans-serif"]}},
    }]
