// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-contentful-js": () => import("./../src/templates/blog-post-contentful.js" /* webpackChunkName: "component---src-templates-blog-post-contentful-js" */),
  "component---src-templates-page-contentful-js": () => import("./../src/templates/page-contentful.js" /* webpackChunkName: "component---src-templates-page-contentful-js" */),
  "component---src-templates-person-contentful-js": () => import("./../src/templates/person-contentful.js" /* webpackChunkName: "component---src-templates-person-contentful-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

